.dlpro-container-loading{
	    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}
@media screen and (max-width: 50em) {
  .Logo2bad-login{
	width: 100%;
  }
}