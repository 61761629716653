/* conteneur englobant l'ensemble des informations */
table{
    border-collapse:collapse;
}
tr>td {
  padding: 5px;
}
table,
tr,
td,
div {
    page-break-inside: avoid;
}
.EtiquetteAccessoires{
	width: 7cm;
	height: 3cm;
	border: solid 0.1px;

}
.logoP2BouPromoAccessoiresPrixDebadCenter{
	display: inline-block;
}
.logoP2BouPromoAccessoiresPromo {
	background-color: #e8182e;
    color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 19px;
	 font-size: 10px;
    font-weight: bold;
   
}
.logoP2BouPromoAccessoiresPrixDebad{
	   display: flex;
    justify-content: center;
      align-items: end;
}
.dlpro-manufacturer-name-custom{
	font-size: 10px;
    width: 100%;
}
.logoP2BouPromoAccessoiresPrixDebad span{
       font-size: 11px;
    margin-right: 2px;
    line-height: 14px;
}
.logoP2BouPromoAccessoiresPrixDebad img{
	        width: 50%;
}
.no-margin-print{margin-top: 20px;}
.no-margin-top-left{
	margin-top: 20px;
	margin-left: 25px;
}
.no-padding-print{
	padding-left: 90px !important;
}
.EtiquetteAccessoiresSize{
	width: 7cm;
	height: 3cm;

}
.accessoiresCheckGreen {
    position: absolute;
    top: 5px;
    left: 5px;
}
.accessoiresCheckGreen img{
    width: 20px;
}  
.accessoiresBlockTop{
	position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
	    margin-top: -10px;    width: inherit;
}
.accessoiresBlockTop img{
	    width: 70px;
}
.accessoiresBlockCenter{
	position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    margin: 10px 0;
}

.etiquetteDailyMailing .EtiquetteAccessoires{

}
.conteneurEtiquetteAccessoires {
      display: block;
    width: 7cm;
	height: 3cm;
}

/* conteneur représentant la partie gauche de l'étiquette (logo, ref, caractéristiques) */
.conteneurCaracteristiquesAccessoires {
	width: 60%;
    float: left;
	    position: relative;
		height: 100%;
}

.logoFabricantAccessoires {
    top: 0;
    width: 100%;
}

.logoFabricantAccessoires img {
  height: 20px;
  margin: 2px 0 0 2px;
}

.logoFabricantAccessoires img + img {
 height: 20px;
    float: right;
    margin-right: 5px;
    margin-top: 5px;
}

.PVCAccessoires img{
	    height: 30px;
}

.bigSizeEtiquetteAccessoires {
      color: red;
    font-size: 11px;
    font-weight: 600;
   width: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}

.smallSizeEtiquetteAccessoires {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.caracteristiquesProduitAccessoires {
  color: var(--grey-color);
  font-weight: 600;
  font-size: 7px;
      position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
}

/* conteneur représentant la partie droite de l'étiquette (PVC, logo P2B ou Promo, PV) */

.conteneurPrixAccessoires {
	width: 40%;
	float: left;
	height: 100%;
	overflow: hidden;
	    position: relative;
}

.PVCAccessoires {
     color: #000;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    
    background: #ffff;
    position: relative;
	    margin-top: 10px;
		 margin-right: 2px;
}

.PVCAccessoiresPrice {
        position: absolute;
    top: 5px;
    right: 0;
    text-align: center;
    font-size: 12px;
    line-height: 10px;
    width: 100%;
}

.PVCAccessoiresPrice span {
	text-decoration: line-through;
}

.logoP2BouPromoAccessoires {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
      margin: 20px 0 2px;
}


.PVAccessoires {
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}


.PVAccessoires {
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: 600;
	    position: relative;
		 margin-right: 2px;
}
.PVAccessoiresPrice{
	    position: absolute;
    top: 2px;
    right: 0;
    text-align: center;
    width: 100%;
    font-size: 14px;
}
@media print {
	.no-print{
		display: none !important;
	}
  table { page-break-after:auto }
  table tr    { page-break-inside:avoid; page-break-after:auto }
  table thead { display:table-header-group }
  table tfoot { display:table-footer-group }
  .page-break  { display: block; page-break-before: always; }
  .no-padding-print{ padding-left: 0 !important;}
  .no-margin-print{margin-top: 0;}
  .no-margin-top-left{ margin-top: 0; margin-left: 0;}
 }
 @media screen and (max-width: 50em) {
  .Logo2bad-login{
	width: 100%;
  }
}
