.home-buttons {
  display: flex;
  justify-content: center;
      flex-wrap: wrap;
}

.csshome-buttons {
  width: 15rem;
  margin: 3rem;
  height: 15rem;
  border-radius: 15px;
  background-color: black;
  color: white;
  font-size: 1.5rem;
}

.svg-icons-home {
  color: red;
}

.csshome-buttons:hover {
  background-color: white;
  color: black;
}
@media screen and (max-width: 50em) {
  .Logo2bad-login{
	width: 100%;
  }
}
