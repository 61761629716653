/* Barre de sélection du format d'étiquette */

.formContainer {
  text-align: center;
  margin-top: 3rem;
}

.form-selector {
  width: 500px;
  height: 50px;
  border-radius: 15px;
  font-family: sans-serif;
  font-size: 18px;
  background-color: black;
  color: white;
  padding-left: 10px;
  box-shadow: 0px 2px 5px grey;
  border: solid 1px white;
  appearance: none;
  cursor: pointer;
}

.form-selector:disabled {
  cursor: default;
}
@media screen and (max-width: 50em) {
  .Logo2bad-login{
	width: 100%;
  }
}
