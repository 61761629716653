* {
  margin: 0;
  box-sizing: border-box;
}

/* COULEURS POUR LES ETIQUETTES */
:root {
  --blue-color: rgb(0, 23, 93);
  --red-color: rgb(247, 0, 0);
  --grey-color: rgb(115, 115, 115);
}

@font-face {
  font-family: "Blair ITC Std";
  src: url("//db.onlinewebfonts.com/t/39647bd50f6ae4309c41f87b703e6b70.eot");
  src: url("//db.onlinewebfonts.com/t/39647bd50f6ae4309c41f87b703e6b70.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/39647bd50f6ae4309c41f87b703e6b70.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/39647bd50f6ae4309c41f87b703e6b70.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/39647bd50f6ae4309c41f87b703e6b70.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/39647bd50f6ae4309c41f87b703e6b70.svg#Blair ITC Std")
      format("svg");
}

.fontEtiquette {
  font-family: "Blair ITC Std", serif;
}

/* Message lors de la déconnexion */
.swal-modal {
  font-family: sans-serif;
  border-radius: 15px;
}
@media screen and (max-width: 50em) {
  .Logo2bad-login{
	width: 100%;
  }
}
.dlpro-block-print{
	
	justify-content: space-evenly;
}
.dlpro-block-print button{
	
	    width: 25%;
}
